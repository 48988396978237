import React from "react";
import { Link } from "react-router-dom";
import {stringToColor} from "../../../../../helpers";

const SingleProductList = (props) => {
   const availability= "In stock";
   const {
      _id,
      product_name,
      price,
      delivery_cost,
      productCode,
      quantity,
      threshold,
      brand,
      des,
   } = props.product;
   console.log(props.product)
   return (
      <div className="col-lg-12 col-xl-6">
         <div className="card">
            <div className="card-body">
               <div className="row m-b-30">
                  <div className="col-md-5 col-xxl-12">
                     <div className="new-arrival-product mb-4 mb-xxl-4 mb-md-0">
                        <div onClick={()=>window.location.href = "/ecom-product-detail/"+`${_id}`} className="new-arrivals-img-contnent">
                           {/*<img className="img-fluid" src={previewImg} alt="" />*/}
                           <div className="card-media-list" style={{backgroundColor: stringToColor(product_name)}}>{product_name.split(" ")[0][0]}{product_name.split(" ")[1]?product_name.split(" ")[1][0]:""}</div>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-7 col-xxl-12">
                     <div className="new-arrival-content position-relative">
                        <h4>
                           <Link to={"/ecom-product-detail/"+`${_id}`}>
                              {product_name}
                           </Link>
                        </h4>
                        <p>
                           Availability:{" "}
                           <span className="item">
                              {availability}{" "}
                              <i className="fa fa-check-circle text-success"></i>
                           </span>
                        </p>
                        <p>
                           Product code:{" "}
                           <span className="item">{productCode}</span>
                        </p>
                        <p>
                           Brand: <span className="item">{brand}</span>
                        </p>
                        {/*<p className="text-content">{des}</p>*/}
                        
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default SingleProductList;
