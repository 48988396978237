import axios from "axios";


import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from "crypto-js";

//export const link = "http://127.0.0.1:5000/api/v1/";
export const link = "https://locate-me-backend.novobyte-sarl.com/api/v1/";
//export const link = "https://4855-154-72-163-187.eu.ngrok.io/api/v1/";

export const secretKey = "http://127.0.0.1:5000/api/v1/";

const getToken = ()=>{
    const token = localStorage.getItem('token')
    var bytes  = (CryptoJS.AES.decrypt(token, secretKey)).toString(CryptoJS.enc.Utf8);
    return bytes
}

export const axios_post=(url, data, res=()=>{}, err=()=>{}, final=()=>{})=>{
    const token = getToken()
    axios.post(link+url, data, {headers: {"authorization" : token}})
        .then(d=>res(d))
        .catch(error=>{console.log(error); err(error.response)})
        .finally(()=>final())
}

export const axios_get=(url, res=()=>{}, err=()=>{}, final=()=>{})=>{
    const token = getToken()
    axios.get(link+url, {headers: {"authorization" : token}})
        .then(d=>res(d.data))
        .catch(error=>{
            console.log(error);
            if (error.response){
                err(error.response)
            }else{
                err(error)
            }
            })
        .finally(()=>final())
}

export const axios_put=(url, data, res=()=>{}, err=()=>{}, final=()=>{})=>{
    console.log(data)
    axios.put(link+url, data)
        .then(d=>res(d.data))
        .catch(error=>{console.log(error); err(error.response)})
        .finally(()=>final())
}

export function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}


