import React, {useEffect, useState} from "react";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

import LogoutPage from './Logout';
/// Image
import profile from "../../../images/profile/17.jpg";
import avatar from "../../../images/product/2.jpg";
import { Dropdown } from "react-bootstrap";
import {axios_get, stringToColor} from "../../../helpers"
import {useSelector, useDispatch} from "react-redux";
import pathReducers from "../../../store/reducers/pathReducer";

const Header = () => {
	const dispacth = useDispatch()
	const selector = useSelector(state => state.todoReducers)
	const selectorPath = useSelector(state => state.path)
	const [profile, setProfile] = useState(null);

  var paths = null;
	if (window.location.pathname !== "/"){
		paths = window.location.pathname.split("/")[1]
		if (paths.includes("app-")){
			paths = paths.split("-")[1]
		}else if(paths.includes("ecom-")){
			paths = paths.split("ecom-")
			if (paths.length > 1){
				paths = (paths[1].split("-")).join(" ")
			}
		}
	}
	console.log(selector)

	var products = selector?selector.list:[]

	console.log(products)

	const removeCart=(e)=>{
		dispacth({
			type : "REMOVE_TO_CART",
			payload : e
		})
	}



	useEffect(()=>{
		getProfile()
	}, [])

	const getProfile=()=>{
		axios_get("vendor/profile", (res)=>{
			setProfile(res.data)
			console.log(res)
		}, (err)=>{
			console.log(err)
		}, ()=>{
			console.log('finish')
		})
	}

  return (
    <div className="header">
		<div className="header-content">
			<nav className="navbar navbar-expand">
				<div className="collapse navbar-collapse justify-content-between">
					<div className="header-left">
						<div
							className="dashboard_bar"
							style={{ textTransform: "capitalize" }}
						  >
							{paths === null ? "Dashboard" : paths}
						</div>
					</div> 	
					<ul className="navbar-nav header-right">
						{/*<li className="nav-item">
							<div className="input-group search-area d-lg-inline-flex d-none mr-5">
							   <input
								  type="text"
								  className="form-control"
								  placeholder="Search here"
							   />
							   <div className="input-group-append">
								  <span className="input-group-text">
									 <svg
										width={20}
										height={20}
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									 >
										<path
										   d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
										   fill="#A4A4A4"
										/>
									 </svg>
								  </span>
							   </div>
							</div>
						</li>*/}
						<Dropdown as="li" className="nav-item  notification_dropdown">
							<Dropdown.Toggle variant=""  className="nav-link  ai-icon i-false" style={{display: "flex", alignItems: "space-around"}}>
								<i className="fa fa-shopping-basket mr-2"></i>
								<span className="badge light text-white bg-primary">{selector.quantity}</span>
							</Dropdown.Toggle>
							<Dropdown.Menu align="right" className="mt-2">
							  <PerfectScrollbar className="widget-media dz-scroll p-3 ps">
								{products.length>0?<ul className="timeline">

								  {products.map(e=><li>
									<div className="timeline-panel">
									  <div className="media mr-2 media-info" style={{backgroundColor: stringToColor(e.data.product_name)}}>
                                          {e.data.product_name.split(" ")[0][0]}{e.data.product_name.split(" ")[1]?e.data.product_name.split(" ")[1][0]:""}
									  </div>
									  <div className="media-body">
										<h6 className="mb-1">{e.data.product_name}</h6>
										<small className="d-block">
										  Quantity : {parseInt(e.quantity).toLocaleString()}
										</small>
									  </div>
										<div onClick={()=>removeCart(e)} style={{cursor: "pointer"}}>
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={20} height={20} fill="red">
												<path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
											</svg>
										</div>
									</div>
								  </li>)}
								</ul>:
									<div style={{display: "flex", justifyContent: "space-around"}}>Nothing inside the cart</div>
								}
								<div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
								  <div
									className="ps__thumb-x"
									tabIndex={0}
									style={{ left: 0, width: 0 }}
								  />
								</div>
								<div className="ps__rail-y" style={{ top: 0, right: 0 }}>
								  <div
									className="ps__thumb-y"
									tabIndex={0}
									style={{ top: 0, height: 0 }}
								  />
								</div>
							  </PerfectScrollbar>
								{products.length>0&&<Link className="all-notification" to="/shopping-cart">
									Shopping Cart <i className="ti-arrow-right" />
								</Link>}
							</Dropdown.Menu>
						</Dropdown>
						<Dropdown as="li" className="nav-item header-profile ">
							{profile&&<Dropdown.Toggle as="a" to="#" variant="" className="nav-link i-false c-pointer">
								<div className="card-media-pp-smal">{profile.company_name.split(" ")[0][0]}{profile.company_name.split(" ")[1]?profile.company_name.split(" ")[1][0]:""}</div>
									 <div className="header-info">
									<span>{profile.company_name.split(" ")[0]}<i className="fa fa-caret-down ml-3" aria-hidden="true"></i></span>
									</div>

							</Dropdown.Toggle>}
							<Dropdown.Menu align="right" className="mt-2">
							  <Link to="/app-profile" className="dropdown-item ai-icon">
								<svg
								  id="icon-user1"
								  xmlns="http://www.w3.org/2000/svg"
								  className="text-primary"
								  width={18}
								  height={18}
								  viewBox="0 0 24 24"
								  fill="none"
								  stroke="currentColor"
								  strokeWidth={2}
								  strokeLinecap="round"
								  strokeLinejoin="round"
								>
								  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
								  <circle cx={12} cy={7} r={4} />
								</svg>
								<span className="ml-2">Profile </span>
							  </Link>
                               <LogoutPage />
							</Dropdown.Menu>
						</Dropdown>
					</ul>
				</div>
			</nav>
		</div>
    </div>
  );
};

export default Header;
