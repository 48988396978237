import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux';
//import logo from '../../images/logo-full.png'
import Loader from '../pages/Loader/Loader';

import Eye from "../../images/eye.svg"
import EyeLock from "../../images/eye-slash.svg"
import Circle from "../../images/circle.svg"
import CircleFill from "../../images/circle-fill.svg"
import axios from "axios";
import {link} from "../../helpers";
import {toast, ToastContainer} from "react-toastify";
import {useHistory} from 'react-router-dom'

function Register(props) {
    const history = useHistory()
    const [fornNum, setFormNu] = useState(0);
    let errorsObj = { email: '', password: ''};
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState({password: "", confirm_password: ""});
    const [showPass, setShowPass] = useState(false);
    const [form, setForm] = useState({name: "", surname: "", company_name: "", article_type: "", average_value: "", average_sales: "", average_sales_period: "", email: ""});

    const dispatch = useDispatch();

    function onSignUp(e) {
        e.preventDefault();
        let error = false;
        console.log("start form")
        const errorObj = { ...errorsObj };
        if (form.email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        }

        if (password.password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }

        if (password.password !== password.confirm_password) {
            errorObj.password = "Doesn't match the password";
            error = true;
        }
        if (fornNum === 0){
            setFormNu(1)
            return;
        }

        setErrors(errorObj);
        form.password = password.password
        if (error) return;

        console.log(form)
        axios.post(link+'vendor', form).then(
            e=>{
                console.log(e)
                history.push("/login")
            }
        ).catch(err=>{
            toast.error(err.response.data.error);
        }).finally(()=>{
            console.log("finally")
        })
    }

    const onblur=(e)=>{
        console.log(e.target.value)
        let error = false;
        const errorObj = { ...errorsObj };
        if (password.password !== e.target.value) {
            errorObj.password = "Doesn't match the password";
            error = true;
        }
        setErrors(errorObj);

        if (error) return;
    }

    const onchangeForm=()=>{
        if (fornNum === 0){
            setFormNu(1)
        }else{
            setFormNu(0)
        }
    }

    const onChange=(e)=>{
        setForm({...form, [e.target.name]: e.target.value})
    }

  return (
   <div className="authincation h-100 p-meddle">
       <ToastContainer />
         <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center">
               <div className="col-md-6">
                  <div className="authincation-content">
                     <div className="row no-gutters">
                            <div className='col-xl-12'>
                                {props.showLoading && <Loader />}
                                <div className='auth-form'>
                                   {fornNum===0&&
                                       <div>
                                           <h3 className="text-center mb-4" style={{color: "#ff712d"}}>LocateMe</h3>
                                            <h4 className="text-center mb-4" style={{color: "#ff712d"}}>Register</h4>
                                       </div>
                                   }
                                    {props.errorMessage && (
                                        <div className='bg-red-300 text-danger border border-red-900 p-1 my-2'>
                                            {props.errorMessage}
                                        </div>
                                    )}
                                    {props.successMessage && (
                                        <div className='bg-green-300 text-danger text-green-900  p-1 my-2'>
                                            {props.successMessage}
                                        </div>
                                    )}
                                    <form onSubmit={onSignUp}>
                                        {fornNum===0&&<div>
                                            <div className='form-group'>
                                                <label className='mb-1'>
                                                  <strong>Name</strong>
                                                </label>
                                                <input type='text' onChange={onChange} className='form-control' placeholder='Name' name='name' />
                                            </div>
                                            <div className='form-group'>
                                                <label className='mb-1'>
                                                  <strong>Surname</strong>
                                                </label>
                                                <input type='text' onChange={onChange} className='form-control' placeholder='Surname' name='surname' />
                                            </div>
                                            <div className='form-group'>
                                                <label className='mb-1'>
                                                  <strong>Email</strong>
                                                </label>
                                                <input type="email" className="form-control"
                                                    value={form.email}
                                                       onChange={onChange}
                                                    placeholder='Email'
                                                    name='email'
                                                />
                                                {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                            </div>
                                            <div className='form-group'>
                                                <label className='mb-1'>
                                                  <strong>Password</strong>
                                                </label>
                                                <div style={{display: 'flex'}}>
                                                    <input type={showPass ? "text" : "password"} className="form-control"
                                                        value={password.password}
                                                        onChange={(e) =>
                                                            setPassword({...password, password: e.target.value})
                                                        }
                                                           placeholder="Password"
                                                    />
                                                    <div class="input-group-prepend">
                                                        <img src={showPass ?Eye: EyeLock} style={{cursor: "pointer"}} width={20} class="input-group-text" onClick={()=>{setShowPass(!showPass)}}/>
                                                    </div>
                                                </div>
                                                {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                                            </div>
                                            <div className='form-group'>
                                                <label className='mb-1'>
                                                    <strong>Confirm Password</strong>
                                                </label>
                                                <input type={showPass ? "text" : "password"} className="form-control"
                                                       value={password.confirm_password}
                                                       onChange={(e) =>
                                                           setPassword({...password, confirm_password: e.target.value})
                                                       }
                                                       placeholder="Password"
                                                       onBlur={onblur}
                                                />
                                            </div>
                                            <div style={{display: "flex", justifyContent: "center", gap: 10}}>
                                                <img src={fornNum===0?CircleFill:Circle} style={{cursor: "pointer"}} onClick={onchangeForm}/>
                                                <img src={fornNum===1?CircleFill:Circle} style={{cursor: "pointer"}} onClick={onchangeForm}/>
                                            </div>
                                        </div>}
                                        {fornNum===1&&<div>
                                            <div className='form-group'>
                                                <label className='mb-1'>
                                                    <strong>Company Name</strong>
                                                </label>
                                                <input type='text' onChange={onChange} value={form.company_name} className='form-control' placeholder='Company name' name='company_name' />
                                            </div>
                                            <div className='form-group'>
                                                <label className='mb-1'>
                                                    <strong>Article Type</strong>
                                                </label>
                                                <input type="text" className="form-control"
                                                       value={form.article_type}
                                                       name='article_type'
                                                       onChange={onChange}
                                                       placeholder='Article type'
                                                />
                                            </div>
                                            <div className='form-group'>
                                                <label className='mb-1'>
                                                    <strong>Average Value</strong>
                                                </label>
                                                <input type='number' value={form.average_value} className='form-control' onChange={onChange} placeholder='Average value' name='average_value' />
                                            </div>
                                            <div className='form-group'>
                                                <label className='mb-1'>
                                                    <strong>Average Sales</strong>
                                                </label>
                                                <input type='number' value={form.average_sales} className='form-control' placeholder='Average sales' onChange={onChange} name='average_sales' />
                                            </div>
                                            <div className='form-group'>
                                                <label className='mb-1'>
                                                    <strong>Average sales period</strong>
                                                </label>
                                                <select className="form-control" id="inputGroupSelect01" value={form.average_sales_period} onChange={onChange} name='average_sales_period'>
                                                    <option selected>Choose...</option>
                                                    <option value="day">Day</option>
                                                    <option value="week">Week</option>
                                                    <option value="month">Month</option>
                                                </select>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "center", gap: 10}}>
                                                <img src={fornNum===0?CircleFill:Circle} style={{cursor: "pointer"}} onClick={onchangeForm}/>
                                                <img src={fornNum===1?CircleFill:Circle} style={{cursor: "pointer"}} onClick={onchangeForm}/>
                                            </div>
                                        </div>}
                                        <div className='text-center mt-4'>
                                            <input type='submit' className='btn btn-primary btn-block'/>
                                        </div>
                                    </form>
                                    <div style={{textAlign: "center"}}>
                                        <p>
                                            Already have an account?{' '}
                                            <Link className='text-primary' to='/login'>
                                                Sign in
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};

export default connect(mapStateToProps)(Register);
