import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>Copyright © <b style={{color: '#ff712d'}}>LocateMe</b> {new Date().getFullYear()}</p>
      </div>
    </div>
  );
};

export default Footer;
