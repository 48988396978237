import React, {Fragment, useEffect, useState} from "react";
import {axios_get, axios_post} from "../../../../../helpers";
import {toast, ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";
import SingleProductList from "../ProductList/SingleProductList";
import {Button, Modal} from "react-bootstrap";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField/TextField";
import SingleSpaceList from "../ProductList/SingleSpaceList";


const SpaceGrid = () => {
    const [space, setSpace] = useState({name: null, capacity: null})
    const [spaces, setSpaces] = useState([])
    const [spaceShow, setSpaceShow] = useState(false)
    const [loader, setLoader] = useState(false)
    const [errorS, setErrorS] = useState({name: false, capacity: false})
    const [error, setError] = useState(false)

    useEffect(()=>{
        getSpace()
    }, [])

    const registerSpace=()=>{
        setErrorS({name: false, capacity: false})
        if(!space.name){
            setErrorS({...errorS, name: true})
            return;
        }else if(!space.capacity){
            setErrorS({...errorS, capacity: true})
            return;
        }
        setLoader(true)
        setSpaceShow(false)
        axios_post("space", space,
            (res)=>{
                console.log(res)
                toast.success("Created");
                getSpace()
            }, (err)=>{
                if(err.message){
                    console.log(err.message)
                    toast.error(err.message);
                }else if(err.data.error){
                    toast.error(err.data.error);
                }
                setError(true)
            },()=>{
                console.log("finaly")
                setLoader(false)
            }
        )
    }

    const getSpace=()=>{
        setLoader(true)
        setError(false)
        axios_get("space/vendor-all",
            (res)=>{
                console.log(res)
                setSpaces(res.data)
            }, (err)=>{
                if(err.message){
                    console.log(err.message)
                    toast.error(err.message);
                }else if(err.data.error){
                    toast.error(err.data.error);
                }
                setError(true)
            },()=>{
                console.log("finaly")
                setLoader(false)
            }
        )
    }

    const onchange=(e)=>{
        e.preventDefault();
        setSpace({...space, [e.target.name]: e.target.value})
    }

    return (
        <Fragment>
            <Modal className="fade" show={spaceShow}>
                <Modal.Header>
                    <Modal.Title>Register Space</Modal.Title>
                    <Button
                        onClick={() => {setSpaceShow(false)}}
                        variant=""
                        className="close"
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                            Name
                        </label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                name="name"
                                value={space.name}
                                onChange={onchange}
                            />
                            {errorS.name && <div className="text-danger fs-12">Name is required</div>}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                            Capacity
                        </label>
                        <div className="col-sm-9">
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Capacity"
                                name="capacity"
                                value={space.capacity}
                                onChange={onchange}
                            />
                            {errorS.capacity && <div className="text-danger fs-12">Capacity is required</div>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setSpaceShow(false)}
                        variant="danger light"
                    >
                        Close
                    </Button>
                    <Button variant="primary" onClick={registerSpace}>Save Space</Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
            {loader&&<div class="d-flex justify-content-center" style={{position: "absolute", top: "40%", left: "55%" }}>
                <div class="spinner-border text-success" role="status" style={{width: "3rem", height: "3rem",}}>
                    <span class="sr-only">Loading...</span>
                </div>
            </div>}
            {spaces.length===0&&!error&&!loader&&<div class="d-flex justify-content-center" style={{position: "absolute", top: "40%", left: "55%"}}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={40} height={40}>
                        <path d="M527.9 224H480v-48c0-26.5-21.5-48-48-48H272l-64-64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h400c16.5 0 31.9-8.5 40.7-22.6l79.9-128c20-31.9-3-73.4-40.7-73.4zM48 118c0-3.3 2.7-6 6-6h134.1l64 64H426c3.3 0 6 2.7 6 6v42H152c-16.8 0-32.4 8.8-41.1 23.2L48 351.4zm400 282H72l77.2-128H528z"/>
                    </svg>
                    <div onClick={()=>setSpaceShow(true)} style={{cursor: "pointer"}}>Add New Space</div>
                </div>
            </div>}
            {error&&<div class="d-flex justify-content-center" style={{position: "absolute", top: "40%", left: "55%"}}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={40} height={40}>
                        <path d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
                    </svg>
                    <div style={{cursor: "pointer"}} onClick={()=>window.location.reload()}>Check your connection</div>
                </div>
            </div>}
            <div className="mb-4" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                <Button variant="primary" onClick={() => setSpaceShow(true)}>Add New Space</Button>
            </div>
            <div className="row">
                {spaces.map((product, i) => (
                    <SingleSpaceList key={product.key} space={product}/>
                ))}
            </div>
        </Fragment>
    );
};

export default SpaceGrid;
