import React, {useEffect, useState} from "react";
import { Link, useParams } from "react-router-dom";
import productData from "../productData";
import {axios_get, stringToColor} from "../../../../../helpers";
import {useDispatch, useSelector} from "react-redux";
import todoReducers from "../../../../../store/reducers/Reducers";
import {toast, ToastContainer} from "react-toastify";
//import {addToCart} from "../../../../../store/actions/index"
const ProductDetail = ({ match }) => {
   //** Dynamic Key Single Place */
   const params = useParams()
   const availability= "In stock";
   const [singleProductData, setSingleProductData] = useState(null)

   useEffect(()=>{
      getProduct()
   }, [])
   console.log(params.id)
   const getProduct=()=>{
      axios_get("store/single/"+params.id, (res)=>{
            setSingleProductData(res.data)
         }, (err)=>{
            console.log(err)
            toast.error(err.data.error);
         }, ()=>{
            console.log("finish")
         }
      )
   }

   console.log(singleProductData)

   const [qty, setQty] = useState(1)

   const dispatch = useDispatch();
   const addToCart= ()=>{
      return {
         type: "ADD_TO_CART",
         payload: {
            qty: parseInt(qty),
            product: singleProductData
         }
      }
   }
   return (
      <div className="h-80">
         <ToastContainer />
         {/*<PageTitle activeMenu="Blank" motherMenu="Layout" />*/}
         {singleProductData ? <div className="row">
            <div className="col-lg-12">
               <div className="card">
                  <div className="card-body">
                     <div className="row">
                        <div className="col-xl-3 col-lg-6  col-md-5 col-xxl-5 " style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                           <div className="card-media" style={{backgroundColor: stringToColor(singleProductData.product_name)}}>{singleProductData.product_name.split(" ")[0][0]}{singleProductData.product_name.split(" ")[1]? singleProductData.product_name.split(" ")[1][0]: ""}</div>
                        </div>
                        <div className="col-xl-9 col-lg-6  col-md-7 col-xxl-7 col-sm-12">
                           <div className="product-detail-content">
                              <div className="new-arrival-content pr">
                                 <h4>{singleProductData.product_name}</h4>
								 <p className="price">{parseInt(singleProductData.price).toLocaleString()} XAF</p>
                                 <p>
                                    Availability:{" "}
                                    <span className="item">
                                       {availability}{" "}
                                       <i className="fa fa-shopping-basket"></i>
                                    </span>
                                 </p>
                                 <p>
                                    Quantity:{" "}
                                    <span className="item">
                                       {parseInt(singleProductData.quantity).toLocaleString()}{" "}
                                       <i className="fa fa-shopping-basket"></i>
                                    </span>
                                 </p>
                                 <p>
                                    Delivery cost:{" "}
                                    <span className="item">{parseInt(singleProductData.delivery_cost).toLocaleString()} XAF</span>
                                 </p>
                                 <p>
                                    Threshold:{" "}
                                    <span className="item">{parseInt(singleProductData.threshold).toLocaleString()}</span>
                                 </p>
                                 <div className="col-2 px-0">
                                    <input
                                       type="number"
                                       name="num"
                                       value={qty}
                                       onChange={(e)=>{setQty(e.target.value)}}
                                       className="form-control input-btn input-number"
                                       defaultValue="1"
                                    />
                                 </div>
                                 <div className="shopping-cart mt-3">
                                    <Link
                                       className="btn btn-primary btn-lg"
                                       onClick={()=>dispatch(addToCart())}
                                    >
                                       <i className="fa fa-shopping-basket mr-2"></i>
                                       Add to cart
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div> : <div></div>}
      </div>
   );
};

export default ProductDetail;
