import React, {Fragment, useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import PageTitle from "../../../../layouts/PageTitle";
import {Button, Dropdown, Modal} from "react-bootstrap";

/// images
import avartar5 from "../../../../../images/avatar/5.png";
import avartar1 from "../../../../../images/avatar/1.png";
import {axios_get, stringToColor} from "../../../../../helpers";

const Customers = () => {
   const [vendors, setVendors] = useState([])
   const [showComplete, setShowComplete] = useState(false)
   const [vendor, setVendor] = useState(null)
   const drop = (e)=>(
      <Dropdown>
         <Dropdown.Toggle variant="" className="table-dropdown icon-false">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
               <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
               >
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                  <circle fill="#000000" cx="19" cy="12" r="2"></circle>
               </g>
            </svg>
         </Dropdown.Toggle>
         <Dropdown.Menu>
            <Dropdown.Item>
               <div onClick={()=>{setShowComplete(true); setVendor(e)}}>Complete Info</div>
            </Dropdown.Item>
            <Dropdown.Item href="#" className="text-danger">
               Delete
            </Dropdown.Item>
         </Dropdown.Menu>
      </Dropdown>
   );

   console.log(vendor)

   useEffect(()=>{
      getVendors()
   }, [])

   const chack = (i) => (
      <div className={`custom-control custom-checkbox ml-2   `}>
         <input
            type="checkbox"
            className="custom-control-input "
            id={`checkAll${i}`}
            required=""
         />
         <label
            className="custom-control-label"
            htmlFor={`checkAll${i}`}
         ></label>
      </div>
   );

   const getVendors = ()=>{
      axios_get("/vendor/vendor-all", (res)=>{
         setVendors(res.data)
         console.log(res.data)
      }, (err)=>{
         console.log(err)
      }, ()=>{
         console.log('finish')
      })
   }

   const completeVendor = ()=>{

   }

   console.log(vendors)

   return (
      <Fragment>
         {vendor&&<Modal className="fade" show={showComplete}>
            <Modal.Header>
               <Modal.Title>Edit Vendor</Modal.Title>
               <Button
                   onClick={() => {setShowComplete(false)}}
                   variant=""
                   className="close"
               >
                  <span>&times;</span>
               </Button>
            </Modal.Header>
            <Modal.Body>
               <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                     ID Card
                  </label>
                  <div className="col-sm-9">
                     <input
                         type="text"
                         className="form-control"
                         placeholder="ID Card"
                         name="id_card"
                         value={vendor.id_card}
                     />
                     {/*{errorS.name && <div className="text-danger fs-12">Name is required</div>}*/}
                  </div>
               </div>
               <div className="form-group row">
               <label className="col-sm-3 col-form-label">
                  Phone Number
               </label>
               <div className="col-sm-9">
                  <input
                      type="number"
                      className="form-control"
                      placeholder="Phone Number"
                      name="phone_number"
                      value={vendor.phone_number}
                  />
                  {/*{errorS.capacity && <div className="text-danger fs-12">Capacity is required</div>}*/}
               </div>
            </div>
               <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                     Payout Frequency
                  </label>
                  <div className="col-sm-9">
                     <select className="form-control" id="inputGroupSelect01" value={vendor.payout_frequency} name='payout_frequency'>
                        <option selected>Choose...</option>
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                     </select>
                     {/*{errorS.capacity && <div className="text-danger fs-12">Capacity is required</div>}*/}
                  </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <Button
                   onClick={() => setShowComplete(false)}
                   variant="danger light"
               >
                  Close
               </Button>
               <Button variant="primary" onClick={completeVendor}>Register</Button>
            </Modal.Footer>
         </Modal>}
         <div className="row">
            <div className="col-lg-12">
               <div className="card">
                  <div className="card-body">
                     <div className="table-responsive">
                        <table className="table mb-0 table-striped">
                           <thead>
                              <tr>
                                 <th className="">
                                    N°
                                 </th>
                                 <th>Company Name</th>
                                 <th>Name</th>
                                 <th>Surname</th>
                                 <th>Email</th>
                                 <th>
                                    Phone
                                 </th>
                                 <th></th>
                              </tr>
                           </thead>
                           <tbody id="customers">
                              {vendors.length>0&&vendors.map((e, i)=>(<tr className="btn-reveal-trigger" key={e._id}>
                                 <td>{i+1}</td>
                                 <td className="py-3">
                                    <Link to={"#"}>
                                       <div className="media d-flex align-items-center">
                                          <div className="avatar-new mr-2">
                                             {e.company_name.split(" ")[0][0]}{e.company_name.split(" ")[1]?e.company_name.split(" ")[1][0]:""}
                                          </div>
                                          <div className="media-body">
                                             <h5 className="mb-0 fs--1">
                                                {e.company_name}
                                             </h5>
                                          </div>
                                       </div>
                                    </Link>
                                 </td>
                                 <td className="py-2">
                                    {e.name}
                                 </td>
                                 <td className="py-2">
                                    {e.surname}
                                 </td>
                                 <td className="py-2">
                                    {e.email}
                                 </td>
                                 <td className="py-2">
                                    {e.prefered_number}
                                 </td>
                                 <td className="py-2 text-right">{drop(e)}</td>
                              </tr>))}
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default Customers;
