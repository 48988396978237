import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///
 import drump from "../../../images/card/drump.png";
import {useDispatch, useSelector, connect} from "react-redux";

class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
      // this.mm("dispose");
      // console.log(this.mm);
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
   /// Open menu
   componentDidMount() {
      // sidebar open/close
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);

   }

   componentDidUpdate(prevProps, prevState, snapshot){
      const selector = useSelector(state => state.path)
      console.log(selector)
   }

   constructor(props){
      super(props)
      this.state={
         path: window.location.pathname
      }
   }

   changePathN = () => {
      console.log("ototottotoototot")
      this.setState({path: window.location.pathname});
   }



   render() {
      var paths = ""
      const changePath=(path, name)=>{
         paths = path;
         return({
            type: "ADD_TO_PATH",
            payload: {
               path: path,
               name: name
            }
         })
      }
      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <MM className="metismenu" id="menu">
                  <li className={`${paths === "/dashboard" ? "mm-active" : ""}`}>
                     <Link className="ai-icon" onClick={()=>this.props.dispatch(changePath("/dashboard", "Dashboard"))} to="/dashboard">
                        <i className="flaticon-381-networking"></i>
                        <span className="nav-text">Dashboard</span>
                     </Link>
                  </li>
                  <li className={`${ paths === "/app-profile"? "mm-active" : ""}`}>
                     <Link
                         className="ai-icon"
                         to="/app-profile"
                         onClick={()=>this.props.dispatch(changePath("app-profile", "Profile"))}
                     >
                        <i class="flaticon-381-settings-6"></i>
                        <span className="nav-text">Profile</span>
                     </Link>
                  </li>
                  <li className={`${ paths === "/ecom-product-add"? "mm-active" : ""}`}>
                     <Link
                         className="ai-icon"
                         to="/ecom-space"
                         onClick={()=>{this.props.dispatch(changePath("ecom-space", "Space"))}}
                     >
                        <i class="flaticon-381-app"></i>
                        <span className="nav-text">Space</span>
                     </Link>
                  </li>
                  <li className={`${ paths === "/ecom-product-add"? "mm-active" : ""}`}>
                     <Link
                         className="ai-icon"
                         to="/ecom-product-add"
                         onClick={()=>{this.props.dispatch(changePath("ecom-product-add", "Add Product"))}}
                     >
                        <i class="flaticon-381-app"></i>
                        <span className="nav-text">Add Product</span>
                     </Link>
                  </li>
                  <li className={`${ paths === "/ecom-product-list"? "mm-active" : ""}`}>
                     <Link
                         className="ai-icon"
                         to="/ecom-product-list"
                         onClick={()=>this.props.dispatch(changePath("ecom-product-list", "Product List"))}
                     >
                        <i class="flaticon-381-box-2"></i>
                        <span className="nav-text">Product List</span>
                     </Link>
                  </li>
                  <li className={`${ paths === "/ecom-product-order"? "mm-active" : ""}`}>
                     <Link
                         className="ai-icon"
                         to="/ecom-product-order"
                         onClick={()=>this.props.dispatch(changePath("ecom-product-order", "Order"))}
                     >
                         <i className="fa fa-shopping-basket"></i>
                        <span className="nav-text">Order</span>
                     </Link>
                  </li>
                  <li className={`${ paths === "/ecom-customers"? "mm-active" : ""}`}>
                     <Link
                         className="ai-icon"
                         to="/ecom-customers"
                         onClick={()=>this.props.dispatch(changePath("ecom-customers", "Vendors Info"))}
                     >
                        <i class="flaticon-381-user-9"></i>
                        <span className="nav-text">Vendors Info</span>
                     </Link>
                  </li>
                  <li className={`${ paths === "/ecom-customers"? "mm-active" : ""}`}>
                     <Link
                         className="ai-icon"
                         to="/ecom-admin-order"
                         onClick={()=>this.props.dispatch(changePath("ecom-admin-order", "Admin Order"))}
                     >
                        <i className="fa fa-shopping-basket"></i>
                        <span className="nav-text">Admin Order</span>
                     </Link>
                  </li>

               </MM>

               <div className="copyright">
                  <p>
                     <strong><b style={{color: '#ff712d'}}>LocateMe</b> Dashboard</strong> ©All Rights Reserved
                  </p>
               </div>
            </PerfectScrollbar>
         </div>
      );
   }
}

export default ()=>{
   const dispatch = useDispatch()
   return(
       <SideBar dispatch={dispatch}/>
   )
};

const mapStateToProps = state => ({
   path: state.path
});

/*export default connect(
    mapStateToProps
)(SideBar);*/
