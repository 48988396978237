const initialData = {
    path: null,
    name: null
}
const pathReducers = (state = initialData, action) =>{
    console.log(action);
    switch(action.type){
        case "ADD_TO_PATH" :
            return{
                path: action.payload.path,
                name: action.payload.name
            }
        default: return state;
    }
}
export default pathReducers;