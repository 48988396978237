import React, {Fragment, useEffect, useState} from "react";
import {Link} from "react-router-dom"
import SingleProductList from "./SingleProductList";
import productData from "../productData";
import {axios_get} from "../../../../../helpers";
import {toast, ToastContainer} from "react-toastify";

const ProductList = () => {
    const [productData, setProductData] = useState([])
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState(false)

    useEffect(()=>{
        getProducts()
    }, [])

    const getProducts=()=>{
        setLoader(true)
        setError(false)
        axios_get("store/vendor-all", (res)=>{
            console.log(res.data)
            setProductData(res.data)
        }, (err)=>{
            console.log(err)
            if(err.message){
                console.log(err.message)
                toast.error(err.message);
            }else if(err.data.error){
                toast.error(err.data.error);
            }
            setError(true)
        },()=>{
            console.log("finish")
            setLoader(false)
        })
    }
    console.log(productData)
   return (
      <Fragment>
          <ToastContainer />
          {loader&&<div class="d-flex justify-content-center" style={{position: "absolute", top: "40%", left: "55%" }}>
              <div class="spinner-border text-success" role="status" style={{width: "3rem", height: "3rem",}}>
                  <span class="sr-only">Loading...</span>
              </div>
          </div>}
          {productData.length===0&&!error&&!loader&&<div class="d-flex justify-content-center" style={{position: "absolute", top: "40%", left: "55%"}}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={40} height={40}>
                      <path d="M527.9 224H480v-48c0-26.5-21.5-48-48-48H272l-64-64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h400c16.5 0 31.9-8.5 40.7-22.6l79.9-128c20-31.9-3-73.4-40.7-73.4zM48 118c0-3.3 2.7-6 6-6h134.1l64 64H426c3.3 0 6 2.7 6 6v42H152c-16.8 0-32.4 8.8-41.1 23.2L48 351.4zm400 282H72l77.2-128H528z"/>
                  </svg>
                  <Link to="/ecom-product-add">Add Products</Link>
              </div>
          </div>}
          {error&&<div class="d-flex justify-content-center" style={{position: "absolute", top: "40%", left: "55%"}}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={40} height={40}>
                      <path d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>
                  </svg>
                  <div style={{cursor: "pointer"}} onClick={()=>window.location.reload()}>Check your connection</div>
              </div>
          </div>}

         <div className="row">
            {productData.map((product, i) => (
               <SingleProductList key={product.key} product={product}/>
            ))}
         </div>
      </Fragment>
   );
};

export default ProductList;
