import React from "react";
import { Link } from "react-router-dom";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import menu03 from "../../../images/menus/3.png";
import menu02 from "../../../images/menus/2.png";
import menu01 from "../../../images/menus/1.png";

import testimonial from "../../../images/testimonial/1.jpg";
import testimonial2 from "../../../images/testimonial/2.jpg";
import testimonial3 from "../../../images/testimonial/3.jpg";

import { Dropdown, Tab, Nav } from "react-bootstrap";

// import Ext from "../../layouts/Ext";

const ApexLine4 = loadable(() =>
   pMinDelay(import("../charts/apexcharts/Line4"), 500)
);
const ApexNagetivePosative = loadable(() =>
   pMinDelay(import("../charts/apexcharts/NagetivePositive2"), 500)
);
const ApexRedialBar = loadable(() =>
   pMinDelay(import("../charts/apexcharts/RadialBar"), 500)
);

const Home = () => {
   console.log("--------------")
   function SampleNextArrow(props) {
      const { onClick } = props;
      return (
         <div className="owl-next" onClick={onClick} style={{ zIndex: 99 }}>
            <i className="fa fa-caret-right" />
         </div>
      );
   }

   function SamplePrevArrow(props) {
      const { onClick } = props;
      return (
         <div
            className="owl-prev disabled"
            onClick={onClick}
            style={{ zIndex: 99 }}
         >
            <i className="fa fa-caret-left" />
         </div>
      );
   }

   const settings = {
      focusOnSelect: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
         {
            breakpoint: 1599,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 990,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
            },
         },
      ],
   };
   return (
      <>
         <div className="row">
            <div className="col-xl col-md-6">
               <div className="card">
                  <div className="card-body p-4">
                     <div className="d-inline-block mb-4 ml--12 position-relative donut-chart-sale">
                        <span
                           className="donut1"
                           data-peity='{ "fill": ["rgb(192, 255, 134)", "rgba(255, 255, 255, 1)"],   "innerRadius": 45, "radius": 10}'
                           style={{ display: "none" }}
                        >
                           4/8
                        </span>
                        <svg className="peity" height={110} width={110}>
                           {/*<path
                              d="M 55 0 A 55 55 0 0 1 55 110 L 55 100 A 45 45 0 0 0 55 10"
                              data-value={1}
                              fill="rgb(192, 255, 134)"
                           />*/}
                           <path
                              d="M 55 110 A 55 55 0 0 1 54.99999999999999 0 L 54.99999999999999 10 A 45 45 0 0 0 55 100"
                              data-value={1}
                              fill="rgba(255, 255, 255, 1)"
                           />
                        </svg>
                        <small className="text-primary">
                           <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={"white"}
                                className="bi bi-basket-fill" viewBox="0 0 16 16">
                              <path
                                  color={"white"}
                                  d="M5.071 1.243a.5.5 0 0 1 .858.514L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 6h1.717L5.07 1.243zM3.5 10.5a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3zm2.5 0a.5.5 0 1 0-1 0v3a.5.5 0 0 0 1 0v-3z"/>
                           </svg>
                        </small>
                        <span className="circle bg-primary" />
                     </div>
                     <h2 className="fs-24 text-black font-w600 mb-0">42</h2>
                     <span className="fs-14">Orders</span>
                  </div>
               </div>
            </div>
            <div className="col-xl col-md-6 col-sm-6">
               <div className="card">
                  <div className="card-body p-4">
                     <div className="d-inline-block mb-4 ml--12 position-relative donut-chart-sale">
                        <span
                           className="donut1"
                           data-peity='{ "fill": ["rgb(255, 195, 210)", "rgba(255, 255, 255, 1)"],   "innerRadius": 45, "radius": 10}'
                           style={{ display: "none" }}
                        >
                           3/8
                        </span>
                        <svg className="peity" height={110} width={110}>
                           <path
                              d="M 93.89087296526012 93.89087296526012 A 55 55 0 1 1 54.99999999999999 0 L 54.99999999999999 10 A 45 45 0 1 0 86.81980515339464 86.81980515339464"
                              data-value={5}
                              fill="rgba(255, 255, 255, 1)"
                           />
                        </svg>
                        <small className="text-primary">
                           <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                                className="bi bi-archive" viewBox="0 0 16 16">
                              <path
                                  color={"white"}
                                  d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                           </svg>
                        </small>
                        <span className="circle bg-danger" />
                     </div>
                     <h2 className="fs-24 text-black font-w600 mb-0">67</h2>
                     <span className="fs-14">Products</span>
                  </div>
               </div>
            </div>
            <div className="col-xl col-md-4 col-sm-6">
               <div className="card">
                  <div className="card-body p-4">
                     <div className="d-inline-block mb-4 ml--12 position-relative donut-chart-sale">
                        <span
                           className="donut1"
                           data-peity='{ "fill": ["rgb(255, 213, 174)", "rgba(255, 255, 255, 1)"],   "innerRadius": 45, "radius": 10}'
                           style={{ display: "none" }}
                        >
                           5/8
                        </span>
                        <svg className="peity" height={110} width={110}>
                           <path
                              d="M 16.109127034739892 93.89087296526012 A 55 55 0 0 1 54.99999999999999 0 L 54.99999999999999 10 A 45 45 0 0 0 23.180194846605364 86.81980515339464"
                              data-value={3}
                              fill="rgba(255, 255, 255, 1)"
                           />
                        </svg>
                        <small className="text-primary">
                           <svg xmlns="http://www.w3.org/2000/svg"
                                width="40" height="40" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" className="feather feather-users">
                              <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" color={"white"}></path>
                              <circle cx="9" cy="7" r="4" color={"white"}></circle>
                              <path d="M23 21v-2a4 4 0 0 0-3-3.87" color={"white"}></path>
                              <path d="M16 3.13a4 4 0 0 1 0 7.75" color={"white"}></path>
                           </svg>
                        </small>
                        <span className="circle bg-warning" />
                     </div>
                     <h2 className="fs-24 text-black font-w600 mb-0">5</h2>
                     <span className="fs-14">Customers</span>
                  </div>
               </div>
            </div>

            <div className="col-xl-12">
               <div className="card">
                  <Tab.Container defaultActiveKey="breakfast">
                     <div className="card-header d-sm-flex d-block pb-0 border-0">
                        <div className="mr-auto pr-3">
                           <h4 className="text-black fs-20">
                              Orders
                           </h4>
                        </div>
                        <div className="card-action card-tabs mt-3 mt-sm-0 mt-3 mb-sm-0 mb-3 mt-sm-0">
                           <Nav as="ul" className="nav nav-tabs" role="tablist">
                              <Nav.Item className="nav-item">
                                 <Nav.Link
                                     data-toggle="tab"
                                     eventKey="breakfast"
                                 >
                                    Pending
                                 </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="nav-item">
                                 <Nav.Link eventKey="lunch">Completed</Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="nav-item">
                                 <Nav.Link eventKey="dinner">Canceled</Nav.Link>
                              </Nav.Item>
                           </Nav>
                        </div>
                        <Link
                            to="/ecom-product-order"
                            className="btn btn-primary rounded d-none d-lg-block ml-0 ml-md-5"
                        >
                           View More
                        </Link>
                     </div>
                     <div className="card-body fito-home-slider">
                        <Tab.Content className="tab-content">
                           <Tab.Pane role="tabpanel" eventKey="breakfast">
                              <div className="featured-menus owl-carousel">
                                 <Slider {...settings}>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="items">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>

                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>

                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                 </Slider>
                              </div>
                           </Tab.Pane>
                           <Tab.Pane
                               // className={`tab-pane fade ${
                               //    activeToggle === "lunch" ? "active show" : ""
                               // }`}
                               id="Lunch"
                               role="tabpanel"
                               eventKey="lunch"
                           >
                              <div className="featured-menus owl-carousel">
                                 <Slider {...settings}>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                 </Slider>
                              </div>
                           </Tab.Pane>
                           <Tab.Pane
                               // className={`tab-pane fade ${
                               //    activeToggle === "dinner" ? "active show" : ""
                               // }`}
                               id="Dinner"
                               role="tabpanel"
                               eventKey="dinner"
                           >
                              <div className="featured-menus owl-carousel">
                                 <Slider {...settings}>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                 </Slider>
                              </div>
                           </Tab.Pane>
                        </Tab.Content>
                     </div>
                  </Tab.Container>
               </div>
            </div>
            <div className="col-xl-12">
               <div className="card">
                  <Tab.Container defaultActiveKey="breakfast">
                     <div className="card-header d-sm-flex d-block pb-0 border-0">
                        <div className="mr-auto pr-3">
                           <h4 className="text-black fs-20">
                              Products List
                           </h4>
                        </div>
                        <Link
                           to="/ecom-product-list"
                           className="btn btn-primary rounded d-none d-lg-block ml-0 ml-md-5"
                        >
                           View More
                        </Link>
                     </div>
                     <div className="card-body fito-home-slider">
                        <Tab.Content className="tab-content">
                           <Tab.Pane role="tabpanel" eventKey="breakfast">
                              <div className="featured-menus owl-carousel">
                                 <Slider {...settings}>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>

                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>

                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                 </Slider>
                              </div>
                           </Tab.Pane>
                           <Tab.Pane
                              // className={`tab-pane fade ${
                              //    activeToggle === "lunch" ? "active show" : ""
                              // }`}
                              id="Lunch"
                              role="tabpanel"
                              eventKey="lunch"
                           >
                              <div className="featured-menus owl-carousel">
                                 <Slider {...settings}>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                 </Slider>
                              </div>
                           </Tab.Pane>
                           <Tab.Pane
                              // className={`tab-pane fade ${
                              //    activeToggle === "dinner" ? "active show" : ""
                              // }`}
                              id="Dinner"
                              role="tabpanel"
                              eventKey="dinner"
                           >
                              <div className="featured-menus owl-carousel">
                                 <Slider {...settings}>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                    <div className="items mr-2">
                                       <div className="d-sm-flex p-3 border border-light rounded">
                                          <Link to="/ecom-product-detail">
                                             <div className="media align-items-center p-3 mb-md-4 mb-3">
                                                <div className="d-inline-block mr-3 position-relative donut-chart-sale2">
                                                   <svg className="peity" height="70" width="70">
                                                      <path
                                                          d="M 35 0 A 35 35 0 0 1 70 35 L 62 35 A 27 27 0 0 0 35 8"
                                                          data-value="2"
                                                          fill="rgb(30, 167, 197)"
                                                      ></path>
                                                      <path
                                                          d="M 70 35 A 35 35 0 1 1 34.99999999999999 0 L 34.99999999999999 8 A 27 27 0 1 0 62 35"
                                                          data-value="6"
                                                          fill="rgba(255, 255, 255, 1)"
                                                      ></path>
                                                   </svg>
                                                   <small className="text-primary">
                                                      <svg
                                                          width={24}
                                                          height={24}
                                                          viewBox="0 0 24 24"
                                                          fill={"none"}
                                                          xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                         <path
                                                             d="M10.8586 5.22596L5.87121 10.5542C5.50758 11.0845 5.64394 11.8068 6.17172 12.1679L11.1945 15.6098V18.9558C11.1945 19.5921 11.6995 20.125 12.3359 20.1376C12.9874 20.1477 13.5177 19.6249 13.5177 18.976V15.0012C13.5177 14.6174 13.3283 14.2588 13.0126 14.0442L9.79041 11.8346L12.5025 8.95833L13.8914 12.1225C14.0758 12.5442 14.4949 12.8169 14.9546 12.8169H19.1844C19.8207 12.8169 20.3536 12.3119 20.3662 11.6755C20.3763 11.024 19.8536 10.4937 19.2046 10.4937H15.7172C15.2576 9.44821 14.7677 8.41285 14.3409 7.35225C14.1237 6.81689 14.0025 6.58457 13.6036 6.21588C13.5227 6.14013 12.9596 5.62498 12.4571 5.16538C11.995 4.74616 11.2828 4.77394 10.8586 5.22596Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M15.6162 5.80678C17.0861 5.80678 18.2778 4.61514 18.2778 3.14517C18.2778 1.6752 17.0861 0.483551 15.6162 0.483551C14.1462 0.483551 12.9545 1.6752 12.9545 3.14517C12.9545 4.61514 14.1462 5.80678 15.6162 5.80678Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M4.89899 23.5164C7.60463 23.5164 9.79798 21.323 9.79798 18.6174C9.79798 15.9117 7.60463 13.7184 4.89899 13.7184C2.19335 13.7184 0 15.9117 0 18.6174C0 21.323 2.19335 23.5164 4.89899 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                         <path
                                                             d="M19.101 23.5164C21.8066 23.5164 24 21.323 24 18.6174C24 15.9117 21.8066 13.7184 19.101 13.7184C16.3954 13.7184 14.202 15.9117 14.202 18.6174C14.202 21.323 16.3954 23.5164 19.101 23.5164Z"
                                                             fill="#1EA7C5"
                                                         />
                                                      </svg>
                                                   </small>
                                                </div>
                                             </div>
                                          </Link>
                                          <div>
                                             <div className="d-flex align-items-center mb-2">
                                                <img
                                                    className="rounded-circle mr-2 profile-image"
                                                    src={testimonial3}
                                                    alt=""
                                                    width={30}
                                                />
                                                <span className="fs-14 text-primary">
                                                   David
                                                </span>
                                             </div>
                                             <h6 className="fs-16 mb-4">
                                                <Link
                                                    to="/ecom-product-detail"
                                                    className="text-black"
                                                >
                                                   Shoes
                                                   41
                                                </Link>
                                             </h6>
                                             {/*<ul>
                                                <li className="mb-2">
                                                   <i className="las la-clock scale5 mr-3" />
                                                   <span className="fs-14 text-black">
                                                      4-6 mins{" "}
                                                   </span>
                                                </li>
                                                <li>
                                                   <i
                                                       className="fa fa-star-o mr-3 scale5 text-warning"
                                                       aria-hidden="true"
                                                   />
                                                   <span className="fs-14 text-black font-w500">
                                                      176 Reviews
                                                   </span>
                                                </li>
                                             </ul>*/}
                                          </div>
                                       </div>
                                    </div>
                                 </Slider>
                              </div>
                           </Tab.Pane>
                        </Tab.Content>
                     </div>
                  </Tab.Container>
               </div>
            </div>
         </div>
      </>
   );
};

export default Home;
