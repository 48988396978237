import React, {Fragment, useEffect, useState} from "react";
import {axios_get, axios_post} from "../../../../../helpers";
import {toast, ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";


const ProductGrid = () => {
    const [product, setProduct] = useState({product_name: null, threshold: null, space: null, quantity: null, delivery_cost: null, price: null})
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState(false)
    const [errorS, setErrorS] = useState(false)
    const [spaces, setSpaces] = useState([])

    useEffect(()=>{
        getSpace()
    }, [])

    const productSave=()=>{
        if(errorS){
            return;
        }
        setLoader(true)
        axios_post("store", product,
            (res)=>{
                console.log(res)
                toast.success("Created");
            }, (err)=>{
                console.log(err.data.error)
                setLoader(false)
                toast.error(err.data.error);
            },()=>{
                console.log("finaly")
                setLoader(false)
            }
        )
    }

    const getSpace=()=>{
        setError(false)
        axios_get("space/vendor-all-free",
            (res)=>{
                console.log(res)
                setSpaces(res.data)
            }, (err)=>{
                if(err.message){
                    console.log(err.message)
                    toast.error(err.message);
                }else if(err.data.error){
                    toast.error(err.data.error);
                }
                setError(true)
            },()=>{
                console.log("finaly")
            }
        )
    }

    const onchange=(e)=>{
        e.preventDefault();
        setProduct({...product, [e.target.name]: e.target.value})
    }

    const onblur=()=>{
        setErrorS(false)
        if(parseInt(product.threshold) > parseInt(product.quantity)){
            setErrorS(true)
        }
    }

    console.log(spaces)

   return (
      <Fragment>
          {loader&&<div style={{width: "100%", height: "40rem", backgroundColor: "rgba(0,0,0,0.1)", display: "flex", zIndex: 10, position: "fixed", alignItems: "center", justifyContent: "space-around"}}>
              <div class="d-flex justify-content-center" style={{position: "absolute", top: "30%", left: "40%" }}>
                  <div class="spinner-border text-success" role="status" style={{width: "3rem", height: "3rem",}}>
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
          </div>}
          {spaces.length===0&&!error&&<div class="d-flex justify-content-center" style={{position: "absolute", top: "40%", left: "55%"}}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={40} height={40}>
                      <path d="M527.9 224H480v-48c0-26.5-21.5-48-48-48H272l-64-64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h400c16.5 0 31.9-8.5 40.7-22.6l79.9-128c20-31.9-3-73.4-40.7-73.4zM48 118c0-3.3 2.7-6 6-6h134.1l64 64H426c3.3 0 6 2.7 6 6v42H152c-16.8 0-32.4 8.8-41.1 23.2L48 351.4zm400 282H72l77.2-128H528z"/>
                  </svg>
                  <Link to="/ecom-space">Click here to add new space first</Link>
              </div>
          </div>}
          <ToastContainer />
          {spaces.length>0&&!error&&<div  style={{display: "flex", justifyContent: "space-around"}}>
              <div className="col-xl-6 col-lg-12">
                  <div className="card">
                      <div className="card-header">
                          <h4 className="card-title">Product Form</h4>
                      </div>
                      <div className="card-body">
                          <div className="basic-form">
                              <div className="form-group row">
                                  <label className="col-sm-3 col-form-label">
                                      Name
                                  </label>
                                  <div className="col-sm-9">
                                      <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Name"
                                          name="product_name"
                                          value={product.product_name}
                                          onChange={onchange}
                                      />
                                  </div>
                              </div>
                              <div className="form-group input-info row"  style={{display: "flex"}}>
                                  <label className="col-sm-3 col-form-label">
                                      Price
                                  </label>
                                  <div className="col-sm-9" style={{display: "flex"}}>
                                      <input type="text"
                                             className="form-control"
                                             placeholder="10000"
                                             name="price"
                                             value={product.price}
                                             onChange={onchange}
                                      />
                                      <div className="input-group-prepend">
                                          <span className="input-group-text">XAF</span>
                                      </div>
                                  </div>
                              </div>
                              <div className="form-group row">
                                  <label className="col-sm-3 col-form-label">
                                      Quantity
                                  </label>
                                  <div className="col-sm-9">
                                      <input
                                          type="number"
                                          className="form-control"
                                          placeholder="100"
                                          name="quantity"
                                          value={product.quantity}
                                          onChange={onchange}
                                      />
                                  </div>
                              </div>
                              <div className="form-group row">
                                  <label className="col-sm-3 col-form-label">
                                      Threshold
                                  </label>
                                  <div className="col-sm-9">
                                      <input
                                          type="number"
                                          className="form-control"
                                          placeholder="10"
                                          name="threshold"
                                          value={product.threshold}
                                          onChange={onchange}
                                          onBlur={onblur}
                                      />
                                      {errorS && <div className="text-danger fs-12">Must be less than quantity</div>}
                                  </div>
                              </div>
                              <div className="form-group input-info row"  style={{display: "flex"}}>
                                  <label className="col-sm-3 col-form-label">
                                      Delivery cost
                                  </label>
                                  <div className="col-sm-9" style={{display: "flex"}}>
                                      <input type="text"
                                             className="form-control"
                                             placeholder="1000"
                                             name="delivery_cost"
                                             value={product.delivery_cost}
                                             onChange={onchange}
                                      />
                                      <div className="input-group-prepend">
                                          <span className="input-group-text">XAF</span>
                                      </div>
                                  </div>
                              </div>
                              <div className="form-group input-info row"  style={{display: "flex"}}>
                                  <label className="col-sm-3 col-form-label">
                                      Space
                                  </label>
                                  <div className="col-sm-9" style={{display: "flex"}}>
                                      <select className="form-control" id="inputGroupSelect01" value={product.space} onChange={onchange} name='space'>
                                          <option selected>Choose one free space...</option>
                                          {spaces&&spaces.map(e=>(
                                              <option value={e._id}>{e.name}</option>
                                          ))}
                                      </select>
                                  </div>
                              </div>
                              <div className="form-group row">
                                  <div className="col-sm-10">
                                      <button
                                          className="btn btn-primary"
                                          onClick={productSave}
                                      >
                                          Register
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>}
      </Fragment>
   );
};

export default ProductGrid;
