import React, {Fragment, useEffect, useState} from "react";

import PageTitle from "../../../layouts/PageTitle";
import { useHistory } from "react-router-dom";

import {Badge, Button, Dropdown, Modal, Table} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";

import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Moment from "moment";
import {axios_get, axios_post} from "../../../../helpers";
import {toast} from "react-toastify";

const ShoppingCart = () => {
    const dispacth = useDispatch()
    const history = useHistory()
    const products = useSelector(state=>state.todoReducers)
    const columns= ["Product Name", "Quantity", "Amount", "Delivery Cost", "Total"];
    const [modalCentered, setModalCentered] = useState(false)
    const [select, setSelect] = useState(null)
    const [registerModal, setRegisterModal] = useState(false)
    const [quantity, setQuantity] = useState(0)
    const [value, setValue] = useState(new Date())
    const [error, setError] = useState({location: false, customer_name: false, customer_phone: false})
    const [form, setForm] = useState({location: null, customer_name: null, customer_phone: null, date: Moment(new Date()).format('DD/MM/YYYY HH:mm'), delivery_cost: 0, total: 0})

    useEffect(()=>{
        getTotal()
    }, [])


    const getTotal = ()=>{
        var delivery_cost = 0
        var total = 0
        products.list.forEach(e=>{
            delivery_cost = delivery_cost+parseInt(e.data.delivery_cost)*parseInt(e.quantity)
            total = total+parseInt(e.data.price)*parseInt(e.quantity)
        })
        total = total-delivery_cost
        setForm({...form, delivery_cost: delivery_cost, total: total})
    }

    console.log(form)

    const registerValue=()=>{
        if(quantity!==0){
            select.quantity = quantity
        }
        setQuantity(0)
        setModalCentered(false)
    }

    const registerOrder=()=>{
        setError({location: false, customer_name: false, customer_phone: false})
        if(!form.location){
            setError({...error, location: true})
            return;
        }else if(!form.customer_name){
            setError({...error, customer_name: true})
            return;
        }else if(!form.customer_phone){
            setError({...error, customer_phone: true})
            return;
        }
        setRegisterModal(false)
        axios_post("order", form, (res)=>{
            console.log(res)
            var id = res.data.data._id
            console.log(id)
            axios_post("order/item/"+id, {products: products.list}, (res)=>{
                console.log(res)
                dispacth({
                    type: 'DELETE_TO_CART'
                })
                history.push('/ecom-product-order')
            }, (err)=>{
                if(err.message){
                    console.log(err.message)
                    toast.error(err.message);
                }else if(err.data.error){
                    toast.error(err.data.error);
                }
            }, ()=>{
                console.log("finish")
            })
        }, (err)=>{
            if(err.message){
                console.log(err.message)
                toast.error(err.message);
            }else if(err.data.error){
                toast.error(err.data.error);
            }
        }, ()=>{
            console.log("finish")
        })
    }

    const onchange=(e)=>{
        setForm({...form, [e.target.name]: e.target.value})
    }

    console.log(form)

    return (
        <div className="h-80">
            {select&&<Modal className="fade" show={modalCentered}>
                <Modal.Header>
                    <Modal.Title>Edit Quantity</Modal.Title>
                    <Button
                        onClick={() => {setModalCentered(false); setSelect(null)}}
                        variant=""
                        className="close"
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                            Quantity
                        </label>
                        <div className="col-sm-9">
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Quantity"
                                name="quantity"
                                value={quantity!==0?quantity:select.quantity}
                                onChange={(e)=>setQuantity(e.target.value)}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant="danger light"
                    >
                        Close
                    </Button>
                    <Button variant="primary" onClick={registerValue}>Save changes</Button>
                </Modal.Footer>
            </Modal>}
            <Modal className="fade" show={registerModal}>
                <Modal.Header>
                    <Modal.Title>Save Order</Modal.Title>
                    <Button
                        onClick={() => {setRegisterModal(false)}}
                        variant=""
                        className="close"
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                            Location
                        </label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Location"
                                name="location"
                                value={form.location}
                                onChange={onchange}
                            />
                            {error.location && <div className="text-danger fs-12">Location is required</div>}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                            Customer Name
                        </label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Customer Name"
                                name="customer_name"
                                value={form.customer_name}
                                onChange={onchange}
                            />
                            {error.customer_name && <div className="text-danger fs-12">Customer Name is required</div>}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                            Customer Phone
                        </label>
                        <div className="col-sm-9">
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Customer Phone"
                                name="customer_phone"
                                value={form.customer_phone}
                                onChange={onchange}
                            />
                            {error.customer_phone && <div className="text-danger fs-12">Customer Phone is required</div>}
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">Delivery Date Time</label>
                        <div className="col-sm-9">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                            Total
                        </label>
                        <label className="col-sm-9 col-form-label" style={{color: '#fd8e1c'}}>
                            {(form.total + form.delivery_cost).toLocaleString()} XAF
                        </label>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                            Delivery cost
                        </label>
                        <label className="col-sm-9 col-form-label">
                            {(form.delivery_cost).toLocaleString()} XAF
                        </label>
                    </div>
                    <div className="form-group row">
                        <strong className="col-sm-3 col-form-label">
                            Gain
                        </strong>
                        <strong className="col-sm-9 col-form-label" style={{color: '#fd8e1c'}}>
                            {(form.total).toLocaleString()} XAF
                        </strong>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setRegisterModal(false)}
                        variant="danger light"
                    >
                        Close
                    </Button>
                    <Button variant="primary" onClick={registerOrder}>Save Order</Button>
                </Modal.Footer>
            </Modal>
            <div className="row">
                <div className="col-lg-12">
                    <div style={{display: "flex", justifyContent: "flex-end"}} className={"mb-4"}>
                        <Button variant="success" onClick={()=>setRegisterModal(true)}>Save changes</Button>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <Table responsive size="sm" className="table-responsive-xl mb-0">
                                <thead>
                                <tr>
                                    <th className="align-middle">
                                        <div className="custom-control custom-checkbox ml-1">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="checkAll"
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="checkAll"
                                            ></label>
                                        </div>
                                    </th>
                                    {columns.map((p, i) => (
                                        <th className="align-middle" key={i}>
                                            <strong> {p}</strong>
                                        </th>
                                    ))}
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody id="orders">
                                {products.list.map((d, i) => (
                                    <tr key={i}>
                                        <td className="align-middle">
                                            <div className="custom-control custom-checkbox ml-1">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="checkAll"
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor="checkAll"
                                                ></label>
                                            </div>
                                        </td>
                                        <td><strong>{d.data.product_name}</strong></td>
                                        <td>{parseInt(d.quantity).toLocaleString()}</td>
                                        <td>{parseInt(d.data.price).toLocaleString()} XAF</td>
                                        <td>{parseInt(d.data.delivery_cost).toLocaleString()} XAF</td>
                                        <td><strong>{(parseInt(d.data.price)*parseInt(d.quantity) - parseInt(d.data.delivery_cost)*parseInt(d.quantity)).toLocaleString()} XAF</strong></td>
                                        <td style={{display: "flex", gap: 20}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={20} height={20} style={{cursor: "pointer"}} onClick={()=>{setSelect(d); setModalCentered(true)}}>
                                                <path d="M373.1 24.97C401.2-3.147 446.8-3.147 474.9 24.97L487 37.09C515.1 65.21 515.1 110.8 487 138.9L289.8 336.2C281.1 344.8 270.4 351.1 258.6 354.5L158.6 383.1C150.2 385.5 141.2 383.1 135 376.1C128.9 370.8 126.5 361.8 128.9 353.4L157.5 253.4C160.9 241.6 167.2 230.9 175.8 222.2L373.1 24.97zM440.1 58.91C431.6 49.54 416.4 49.54 407 58.91L377.9 88L424 134.1L453.1 104.1C462.5 95.6 462.5 80.4 453.1 71.03L440.1 58.91zM203.7 266.6L186.9 325.1L245.4 308.3C249.4 307.2 252.9 305.1 255.8 302.2L390.1 168L344 121.9L209.8 256.2C206.9 259.1 204.8 262.6 203.7 266.6zM200 64C213.3 64 224 74.75 224 88C224 101.3 213.3 112 200 112H88C65.91 112 48 129.9 48 152V424C48 446.1 65.91 464 88 464H360C382.1 464 400 446.1 400 424V312C400 298.7 410.7 288 424 288C437.3 288 448 298.7 448 312V424C448 472.6 408.6 512 360 512H88C39.4 512 0 472.6 0 424V152C0 103.4 39.4 64 88 64H200z"/>
                                            </svg>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={20} height={20} fill="red" style={{cursor: "pointer"}} onClick={()=>{setSelect(d); setModalCentered(true)}}>
                                                <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
                                            </svg>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShoppingCart;
