import React, {Fragment, useEffect, useState} from "react";
import { Link } from "react-router-dom";
//** Import Image */
import profile01 from "../../../../images/profile/1.jpg";
import profile02 from "../../../../images/profile/2.jpg";
import profile03 from "../../../../images/profile/3.jpg";
import profile04 from "../../../../images/profile/4.jpg";
import profile05 from "../../../../images/profile/5.jpg";
import profile06 from "../../../../images/profile/6.jpg";
import profile07 from "../../../../images/profile/7.jpg";
import profile08 from "../../../../images/profile/8.jpg";
import profile09 from "../../../../images/profile/9.jpg";
import profile from "../../../../images/profile/profile.png";
import { Dropdown, Button, Modal } from "react-bootstrap";

import PageTitle from "../../../layouts/PageTitle";

import { SRLWrapper } from "simple-react-lightbox";
import {axios_get, stringToColor} from "../../../../helpers";

const AppProfile = () => {
   const [activeToggle, setActiveToggle] = useState("posts");
   const [sendMessage, setSendMessage] = useState(false);
   const [profile, setProfile] = useState(null);

   useEffect(()=>{
      getProfile()
   }, [])

   const getProfile=()=>{
      axios_get("vendor/profile", (res)=>{
         setProfile(res.data)
         console.log(res)
      }, (err)=>{
         console.log(err)
      }, ()=>{
         console.log('finish')
      })
   }
   console.log(profile)
   return (
      <Fragment>

         <div className="row">
            <div className="col-lg-12">
               <div className="profile card card-body px-3 pt-3 pb-0">
                  <div className="profile-head">
                     <div className="photo-content">
                        <div className="cover-photo"></div>
                     </div>
                     <div className="profile-info">
                        {profile&&<div className="profile-photo">
                           <div className="card-media-pp rounded-circle" style={{backgroundColor: stringToColor(profile.company_name)}}>{profile.company_name.split(" ")[0][0]}{profile.company_name.split(" ")[1]?profile.company_name.split(" ")[1][0]:""}</div>
                        </div>}
                        <div className="profile-details">
                           <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0">
                                 LocateMe
                              </h4>
                              <p>Company Name</p>
                           </div>
                           <div className="profile-email px-2 pt-2">
                              <h4 className="text-muted mb-0">
                                 locateme@gmail.com
                              </h4>
                              <p>Email</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="row" style={{display: "flex", justifyContent: "space-around"}}>
            <div className="col-xl-8">
               <div className="card">
                  <div className="card-body">
                     <div className="profile-tab">
                        <div className="custom-tab-1">
                           <ul className="nav nav-tabs">
                              <li
                                 className="nav-item"
                                 onClick={() => setActiveToggle("posts")}
                              >
                                 <Link
                                    to="#about-me"
                                    data-toggle="tab"
                                    className={`nav-link ${
                                       activeToggle === "posts"
                                          ? "active show"
                                          : ""
                                    }`}
                                 >
                                    About Me
                                 </Link>
                              </li>
                              <li className="nav-item">
                                 <Link
                                    to="#profile-settings"
                                    data-toggle="tab"
                                    onClick={() => setActiveToggle("setting")}
                                    className={`nav-link ${
                                       activeToggle === "setting"
                                          ? "active show"
                                          : ""
                                    }`}
                                 >
                                    Setting
                                 </Link>
                              </li>
                           </ul>
                           <div className="tab-content">
                              <div
                                 id="about-me"
                                 className={`tab-pane fade ${
                                     activeToggle === "posts"
                                         ? "active show"
                                         : ""
                                 }`}
                              >
                                 <div className="profile-personal-info">
                                    <div className="row mb-2 mt-4">
                                       <div className="col-sm-3">
                                          <h5 className="f-w-500">
                                             Name
                                             <span className="pull-right d-none d-sm-block">
                                                :
                                             </span>
                                          </h5>
                                       </div>
                                       <div className="col-sm-9">
                                          <span>LocateMe</span>
                                       </div>
                                    </div>
                                    <div className="row mb-2 mt-4">
                                       <div className="col-sm-3">
                                          <h5 className="f-w-500">
                                             Surname
                                             <span className="pull-right d-none d-sm-block">
                                                :
                                             </span>
                                          </h5>
                                       </div>
                                       <div className="col-sm-9">
                                          <span>LocateMe</span>
                                       </div>
                                    </div>
                                    <div className="row mb-2 mt-4">
                                       <div className="col-sm-3">
                                          <h5 className="f-w-500">
                                             Company Name
                                             <span className="pull-right d-none d-sm-block">
                                                :
                                             </span>
                                          </h5>
                                       </div>
                                       <div className="col-sm-9">
                                          <span>LocateMe</span>
                                       </div>
                                    </div>
                                    <div className="row mb-2 mt-4">
                                       <div className="col-sm-3">
                                          <h5 className="f-w-500">
                                             Article Type
                                             <span className="pull-right d-none d-sm-block">
                                                :
                                             </span>
                                          </h5>
                                       </div>
                                       <div className="col-sm-9">
                                          <span>LocateMe</span>
                                       </div>
                                    </div>
                                    <div className="row mb-2 mt-4">
                                       <div className="col-sm-3">
                                          <h5 className="f-w-500">
                                             Article Value
                                             <span className="pull-right d-none d-sm-block">
                                                :
                                             </span>
                                          </h5>
                                       </div>
                                       <div className="col-sm-9">
                                          <span>example@examplel.com</span>
                                       </div>
                                    </div>
                                    <div className="row mb-2 mt-4">
                                       <div className="col-sm-3">
                                          <h5 className="f-w-500">
                                             Article Sales
                                             <span className="pull-right d-none d-sm-block">
                                                :
                                             </span>
                                          </h5>
                                       </div>
                                       <div className="col-sm-9">
                                          <span>LocateMe</span>
                                       </div>
                                    </div>
                                    <div className="row mb-2 mt-4">
                                       <div className="col-sm-3">
                                          <h5 className="f-w-500">
                                             Article Sales Period
                                             <span className="pull-right d-none d-sm-block">
                                                :
                                             </span>
                                          </h5>
                                       </div>
                                       <div className="col-sm-9">
                                          <span>LocateMe</span>
                                       </div>
                                    </div>
                                    <div className="row mb-2 mt-4">
                                       <div className="col-sm-3">
                                          <h5 className="f-w-500">
                                             ID Card
                                             <span className="pull-right d-none d-sm-block">
                                                :
                                             </span>
                                          </h5>
                                       </div>
                                       <div className="col-sm-9">
                                          <span>LocateMe</span>
                                       </div>
                                    </div>
                                    <div className="row mb-2 mt-4">
                                       <div className="col-sm-3">
                                          <h5 className="f-w-500">
                                             Phone numbers
                                             <span className="pull-right d-none d-sm-block">
                                                :
                                             </span>
                                          </h5>
                                       </div>
                                       <div className="col-sm-9">
                                          <span>LocateMe</span>
                                       </div>
                                    </div>
                                    <div className="row mb-2 mt-4">
                                       <div className="col-sm-3">
                                          <h5 className="f-w-500">
                                             Preferred Phone
                                             <span className="pull-right d-none d-sm-block">
                                                :
                                             </span>
                                          </h5>
                                       </div>
                                       <div className="col-sm-9">
                                          <span>Full Time (Free Lancer)</span>
                                       </div>
                                    </div>
                                    <div className="row mb-2 mt-4">
                                       <div className="col-sm-3">
                                          <h5 className="f-w-500">
                                             Payout Frequency
                                             <span className="pull-right d-none d-sm-block">
                                                :
                                             </span>
                                          </h5>
                                       </div>
                                       <div className="col-sm-9">
                                          <span>27</span>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div
                                 id="profile-settings"
                                 className={`tab-pane fade ${
                                    activeToggle === "setting"
                                       ? "active show"
                                       : ""
                                 }`}
                              >
                                 <div className="pt-3">
                                    <div className="settings-form">
                                       <form className="mt-2"
                                          onSubmit={(e) => e.preventDefault()}
                                       >
                                          <div className="form-row">
                                             <div className="form-group col-md-6">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    placeholder="Name"
                                                    className="form-control"
                                                />
                                             </div>
                                             <div className="form-group col-md-6">
                                                <label>Surname</label>
                                                <input
                                                    type="text"
                                                    placeholder="Surname"
                                                    className="form-control"
                                                />
                                             </div>
                                          </div>
                                          <div className="form-row">
                                             <div className="form-group col-md-6">
                                                <label>Email</label>
                                                <input
                                                    type="email"
                                                    placeholder="Email"
                                                    className="form-control"
                                                />
                                             </div>
                                             <div className="form-group col-md-6">
                                                <label>Password</label>
                                                <input
                                                    type="password"
                                                    placeholder="Password"
                                                    className="form-control"
                                                />
                                             </div>
                                          </div>
                                          <div className="form-row">
                                             <div className="form-group col-md-6">
                                                <label>ID Card</label>
                                                <input
                                                    type="text"
                                                    placeholder="ID Card"
                                                    className="form-control"
                                                />
                                             </div>
                                             <div className="form-group col-md-6">
                                                <label>Phone</label>
                                                <input
                                                    type="text"
                                                    placeholder="Phone"
                                                    className="form-control"
                                                />
                                             </div>
                                          </div>
                                          <div className="form-row">
                                             <div className="form-group col-md-6">
                                                <label>Company Name</label>
                                                <input
                                                    type="text"
                                                    placeholder="Company Name"
                                                    className="form-control"
                                                />
                                             </div>
                                             <div className="form-group col-md-6">
                                                <label>Article Type</label>
                                                <input
                                                    type="text"
                                                    placeholder="Article Type"
                                                    className="form-control"
                                                />
                                             </div>
                                          </div>
                                          <div className="form-row">
                                             <div className="form-group col-md-6">
                                                <label>Article Value</label>
                                                <input
                                                    type="text"
                                                    placeholder="Article Value"
                                                    className="form-control"
                                                />
                                             </div>
                                             <div className="form-group col-md-6">
                                                <label>Article Sales</label>
                                                <input
                                                    type="text"
                                                    placeholder="Article Sales"
                                                    className="form-control"
                                                />
                                             </div>
                                          </div>
                                          <div className="form-row">
                                             <div className="form-group col-md-6">
                                                <label>Article Sales Period</label>
                                                <select
                                                    className="form-control"
                                                    id="inputState"
                                                    defaultValue="option-1"
                                                >
                                                   <option value="option-1">
                                                      Choose...
                                                   </option>
                                                   <option value="option-2">
                                                      Day
                                                   </option>
                                                   <option value="option-3">
                                                      Week
                                                   </option>
                                                   <option value="option-4">
                                                      Month
                                                   </option>
                                                </select>
                                             </div>
                                             <div className="form-group col-md-6">
                                                <label>Payout Frequency</label>
                                                <select
                                                    className="form-control"
                                                    id="inputState"
                                                    defaultValue="option-1"
                                                >
                                                   <option value="option-1">
                                                      Choose...
                                                   </option>
                                                   <option value="option-2">
                                                      Day
                                                   </option>
                                                   <option value="option-3">
                                                      Week
                                                   </option>
                                                   <option value="option-4">
                                                      Month
                                                   </option>
                                                </select>
                                             </div>
                                          </div>
                                          <button
                                             className="btn btn-primary mt-4"
                                             type="submit"
                                          >
                                             Save
                                          </button>
                                       </form>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default AppProfile;
