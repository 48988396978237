import React from "react";
import { Link } from "react-router-dom";
import {stringToColor} from "../../../../../helpers";
import {Badge} from "react-bootstrap";

const SingleSpaceList = (props) => {
    const {
        _id,
        name,
        capacity,
        availability,
        product,
    } = props.space;
    console.log(props.space)
    return (
        <div className="col-lg-12 col-xl-6">
            <div className="card">
                <div className="card-body">
                    <div className="row m-b-30">
                        <div className="col-md-5 col-xxl-12">
                            <div className="new-arrival-product mb-4 mb-xxl-4 mb-md-0">
                                <div className="new-arrivals-img-contnent">
                                    {/*<img className="img-fluid" src={previewImg} alt="" />*/}
                                    <div className="card-media-list" style={{backgroundColor: stringToColor(name)}}>{name.split(" ")[0][0]}{name.split(" ")[1]?name.split(" ")[1][0]:""}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-xxl-12">
                            <div className="new-arrival-content position-relative">
                                <h4>
                                    {name}
                                </h4>
                                <p>
                                    Availability:{" "}
                                    <span className="item">
                              {availability} %{" "}
                                        <i className="fa fa-check-circle text-success"></i>
                           </span>
                                </p>
                                <p>
                                    Capacity:{" "}
                                    <span className="item">{capacity}</span>
                                </p>
                                <p>
                                    Product {"  "}
                                    <Badge variant="primary">
                                        {product ? product:"No product"}
                                    </Badge>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleSpaceList;
