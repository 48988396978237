const initialData = {
	list : [
	],
	quantity: 0
}
const todoReducers = (state = initialData, action) =>{
	console.log(action);
	var exist = false;
	switch(action.type){
		case "ADD_TO_CART" :
			state.list.forEach(e=>{
				console.log(e.data._id)
				console.log(action.payload.product._id)
				if (e.data._id === action.payload.product._id){
					exist = true;
					e.quantity = e.quantity+action.payload.qty
					return;
				}
			})
			if (!exist){
				return{
					...state,
					list: [
						...state.list,
						{
							data: action.payload.product,
							quantity: action.payload.qty,
						}
					],
					quantity: state.quantity+action.payload.qty
				}
			}else{
				return{
					...state,
					quantity: state.quantity+action.payload.qty
				}
			}
		case "REMOVE_TO_CART" :
			console.log(state.list)
			console.log(action.payload)
			var data = state.list.filter(e=>{
				return e.data._id !== action.payload.data._id
			})
			return{
				...state,
				list: data,
				quantity: state.quantity - action.payload.quantity
			}
		case "DELETE_TO_CART" :
			return{
				...state,
				list: [],
				quantity: 0
			}
		default: return state;
	}
}
export default todoReducers;