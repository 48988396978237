import React, {Fragment, useEffect, useState} from "react";

import data from "../../table/tableData";
import {Badge, Button, Dropdown, Modal, Table} from "react-bootstrap";
import {axios_get} from "../../../../helpers";

const AdminOrder = () => {
    const columns= ["Order Id", "Customer (Phone)", "Location", "Date", "Delivery Cost", "Total", "State"];
    const columnsItem= ["Product Name", "Quantity", "Price", "Delivery Cost", "Total"];
    const [orders, setOrders] = useState([])
    const [items, setItems] = useState([])
    const [showItem, setShowItem] = useState(false)
    const [loader, setLoader] = useState(false)

    useEffect(()=>{
        getOrdes()
    }, [])

    const getOrdes=()=>{
        setLoader(true)
        axios_get('order/vendor-all', (res)=>{
            console.log(res.data)
            setOrders(res.data)
        }, (err)=>{
            console.log(err)
        }, ()=>{
            setLoader(false)
            console.log("finish")
        })
    }

    const getOrderItem=(e)=>{
        setLoader(true)
        axios_get('order/vendor-all/'+e._id, (res)=>{
            console.log(res.data)
            setShowItem(true)
            setItems(res.data)
        }, (err)=>{
            console.log(err)
        }, ()=>{
            setLoader(false)
            console.log("finish")
        })
    }

    const changeState=(e)=>{
        console.log(e)
    }

    return (
        <div className="h-80">
            {loader&&<div style={{width: "100%", height: "40rem", display: "flex", zIndex: 10, position: "fixed", alignItems: "center", justifyContent: "space-around"}}>
                <div class="d-flex justify-content-center" style={{position: "absolute", top: "30%", left: "30%" }}>
                    <div class="spinner-border text-success" role="status" style={{width: "3rem", height: "3rem",}}>
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>}
            <Modal className="fade" show={showItem} size={"xl"}>
                <Modal.Header>
                    <Modal.Title>Order Items</Modal.Title>
                    <Button
                        variant=""
                        className="close"
                        onClick={()=>setShowItem(false)}
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <Table responsive size="sm" className="table-responsive-xl mb-0">
                            <thead>
                            <tr>
                                {columnsItem.map((p, i) => (
                                    <th className="align-middle" key={i}>
                                        <strong> {p}</strong>
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody id="orders" className="mb-2">
                            {items.map((d, i) => (
                                <tr key={i}>
                                    <td>{d.store_id.product_name}</td>
                                    <td>{parseInt(d.quantity)}</td>
                                    <td>{parseInt(d.price).toLocaleString()} XAF</td>
                                    <td>{parseInt(d.delivery_cost).toLocaleString()} XAF</td>
                                    <td>{(parseInt(d.quantity)*parseInt(d.price)-parseInt(d.quantity)*parseInt(d.delivery_cost)).toLocaleString()} XAF</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={()=>setShowItem(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <Table responsive size="sm" className="table-responsive-xl mb-0">
                                <thead>
                                <tr>
                                    {columns.map((p, i) => (
                                        <th className="align-middle" key={i}>
                                            <strong> {p}</strong>
                                        </th>
                                    ))}
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody id="orders">
                                {orders.map((d, i) => (
                                    <tr key={i}>
                                        <td><strong>#{d.token}</strong></td>
                                        <td>{d.customer_name} ({parseInt(d.customer_phone).toLocaleString()})</td>
                                        <td>{d.location}</td>
                                        <td>{d.delivery_date}</td>
                                        <td>{parseInt(d.delivery_cost).toLocaleString()} XAF</td>
                                        <td>{parseInt(d.amount).toLocaleString()} XAF</td>
                                        <td>
                                            {d.state=== "PENDING" ? <Badge variant="warning">
                                                Pending
                                            </Badge> : d.state=== "COMPLETED" ? <Badge variant="success">
                                                Completed
                                            </Badge> : <Badge variant="danger">
                                                Canceled
                                            </Badge>}
                                        </td>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant=""
                                                className="table-dropdown icon-false"
                                            >
                                            </Dropdown.Toggle>
                                            {d.state==="PENDING"&&<Dropdown.Menu>
                                                <Dropdown.Item>
                                                    <div onClick={(e)=>{e.preventDefault(); getOrderItem(d)}}>View More</div>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <div onClick={(e)=>{e.preventDefault(); changeState(d)}}>Canceled</div>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <div onClick={(e)=>{e.preventDefault(); changeState(d)}}>Completed</div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>}
                                            {(d.state==="COMPLETED"||d.state==="CANCELED")&&<Dropdown.Menu>
                                                <Dropdown.Item>
                                                    <div onClick={(e)=>{e.preventDefault(); getOrderItem(d)}}>View More</div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>}
                                        </Dropdown>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminOrder;
