import {lazy, Suspense, useEffect, useState} from 'react';
/// Components
import Index from './jsx/index';
import {connect, useDispatch, useSelector} from 'react-redux';
import {  Route, Switch, useHistory } from 'react-router-dom';

import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import {secretKey} from "./helpers";
import CryptoJS  from 'crypto-js';
import withRouter from "react-router-dom/es/withRouter";


const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
    return new Promise(resolve => {
		setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
	});
});
function App (props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    const selector = useSelector(state=>state.auth)
    console.log(selector)

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token){
            var bytes  = CryptoJS.AES.decrypt(token, secretKey);
            if (bytes.toString(CryptoJS.enc.Utf8)){
                setIsAuthenticated(true)
                //window.location.reload()
            }else{
                setIsAuthenticated(false)
                localStorage.clear()
                window.location.href = "/login"
                //history.push("/login")
            }
        }else{
            history.push("/login")
        }
    }, []);
    
    let routes = (  
        <Switch>
            <Route path='/login' component={Login} />
            <Route path='/page-register' component={SignUp} />
            <Route path='/page-forgot-password' component={ForgotPassword} />
        </Switch>
    );
    if (isAuthenticated) {
		return (
			<>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>  
                   }
                >
                    <Index />
                </Suspense>
            </>
        );
	
	}else{
		return (
			<div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    {routes}
                </Suspense>
			</div>
		);
	}
};

/*const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};
*/
//export default withRouter(connect(mapStateToProps)(App));
export default (App);
